import { SearchResults } from '@dte/otw/azure-functions/src/common/interfaces/admin/search/SearchResults';
import { PropsWithChildren, useMemo } from 'react';
import { useAuthenticatedJsonGet } from '../../../../../common/util/services/useAuthenticatedJsonGet';
import { getSearchURL } from '../../../../../components/header/search/getSearchURL';
import { useValidatedStringQueryParam } from '../../../../../hooks/parameters/useValidatedStringQueryParam';

export interface PropsWithJobId extends PropsWithChildren {
	jobId: string;
}

function useSearchDisplayId(queryValue: string) {
	const url = getSearchURL(queryValue);

	const [searchResults] = useAuthenticatedJsonGet<SearchResults>(url);
	const searchJobId = useMemo(() => {
		return searchResults?.jobs?.[0]?.jobId;
	}, [searchResults]);

	return searchJobId;
}

function useJobIdFromQuery() {
	const queryValue = useValidatedStringQueryParam('jobId');

	let possibleDisplayId: string;
	if (queryValue?.length === 13) {
		possibleDisplayId = queryValue;
	}
	const searchJobId = useSearchDisplayId(possibleDisplayId);

	if (possibleDisplayId) {
		if (searchJobId) {
			return searchJobId;
		}
		return undefined;
	}

	return queryValue;
}

export function useJobId(jobId?: string) {
	const queryValue = useJobIdFromQuery();
	return jobId ?? queryValue;
}
