'use client';

import { ReactNode } from 'react';
import { JobDisplayId } from '../jobDisplayId/JobDisplayId';
import { PropsWithJobId, useJobId } from '../useJobId';
import ConditionalLink from './ConditionalLink';

export function JobLink(props: PropsWithJobId): ReactNode {
	const jobId = useJobId(props.jobId);
	if (!jobId) {
		return <>-</>;
	}

	let content: ReactNode;
	if (props.children) {
		content = props.children;
	} else {
		content = <JobDisplayId jobId={jobId} />;
	}

	const link = `/jobs/?jobId=${jobId}`;
	return <ConditionalLink href={link}>{content}</ConditionalLink>;
}
