'use client';

import { JobStatusDocument } from '@dte/otw/azure-functions/src/common/interfaces/containers/jobs/JobStatus';
import { JobStatusResponse } from '@dte/otw/azure-functions/src/common/interfaces/containers/jobs/JobStatusResponse';
import { ReactNode, useState } from 'react';
import AuthenticatedJsonContent from '../../../content/jsonGet/AuthenticatedJsonContent';
import { getJobStatusURL } from '../getJobStatusURL';
import { PropsWithJobId, useJobId } from '../useJobId';

interface ContentProps {
	jobId: string;
	jobStatus: JobStatusDocument;
}

function Content(props: ContentProps): ReactNode {
	const jobId = props.jobId;
	const jobStatus = props.jobStatus;

	let displayId = jobStatus?.data?.jobDisplayId;
	// Fall back on job id (shouldn't need this)
	if (!displayId) {
		displayId = jobId;
	}

	return <span>{displayId}</span>;
}

export function JobDisplayId(props: PropsWithJobId): ReactNode {
	const jobId = useJobId(props.jobId);

	const url = getJobStatusURL(jobId);
	const [response, setResponse] = useState<JobStatusResponse>();

	const jobStatus = response?.status;

	return (
		<AuthenticatedJsonContent url={url} setData={setResponse}>
			<Content jobId={jobId} jobStatus={jobStatus} />
		</AuthenticatedJsonContent>
	);
}
